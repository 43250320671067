/**
 * AntDesign Form is used for validation.
 * Component is connected to Form for using required functions.
 * getFieldDecorator -> Creates a form field with given rules.
 * validateFields -> Checks all fields according to rules and returns errors.
 * "email, code" parameters are required from query.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Spin, Icon } from 'antd';
import i18n from '../../../plugins/i18n';
import { actions } from '../../../store/modules';

class Activate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: {
        code: '',
        email: ''
      },
      grant: 'urn:ietf:params:oauth:grant-type:onetimetoken',
      activateSuccess: true,
      activateStatus: 'asd'
    };
  };

  componentDidMount() {
    let query = queryString.parse(this.props.location.search);
    if (query.email && query.code) {
      this.setState({
        user: {
          email: query.email,
          code: query.code
        }
      }, () => {
        this.activate();
      });
    }
    else {
      this.props.history.push('/sign-in');
    }
  };

  activate = () => {
    this.setState({ loading: true });
    let _this = this;
    this.props.dispatch(actions.auth.activate(this.state.user)).then(() => {
      this.setState({ loading: false, activateSuccess: true, activateStatus: i18n.t('lbl.activateTextSuccess') }, () => {
        setTimeout(() => {
          _this.props.dispatch(actions.auth.signIn({ username: _this.state.user.email, password: _this.state.user.code, grant_type: _this.state.grant })).then(() => {
            window.location.href = process.env.REACT_APP_DASHBOARD_URL;
          }).catch(() => {
            _this.setState({ loading: false, activateSuccess: false, activateStatus: i18n.t('msg.autoLoginError') }, () => {
              setTimeout(() => {
                _this.props.history.push('/sign-in');
              }, 2000);
            });
          });
        }, 2000);
      });
    }).catch(error => {
      let text = error.errors && error.errors[0] ? error.errors[0].error : i18n.t('msg.unknownError');
      this.setState({ loading: false, activateSuccess: false, activateStatus: text }, () => {
        setTimeout(() => {
          _this.props.history.push('/sign-in');
        }, 2000);
      });
    });
  };

  render() {
    let { activateStatus, activateSuccess, loading } = this.state;
    return (
      <div className="page-content">
        {loading ?
          <Spin size="large" indicator={<Icon type="loading" />} />
          :
          <React.Fragment>
            {activateSuccess !== null && activateStatus &&
              <React.Fragment>
                <h1>{i18n.t(`lbl.activate${activateSuccess === true ? 'Success' : 'Error'}`)}</h1>
                <p className="page-text">{activateStatus}</p>
                <p className="text-center">{i18n.t('lbl.redirectingDashboard')}</p>
              </React.Fragment>
            }
          </React.Fragment>
        }
      </div>
    );
  };
};

const mapStateToProps = ({ auth }) => ({ ...auth });
export default connect(mapStateToProps)(Activate);