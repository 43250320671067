/**
 * Path : Route url
 * Name : Name of the route as a key
 * Component : What to render
 * Routes : Sub-routes of the parent
 */

const auth = {
  path: '/',
  name: 'auth',
  component: require('../../components/Auth/Layout/Layout').default,
  routes: [
    {
      path: '/sign-in',
      name: 'auth.signIn',
      component: require('../../components/Auth/Views/SignIn').default,
    },
    {
      path: '/sign-up',
      name: 'auth.signUp',
      component: require('../../components/Auth/Views/SignUp').default,
    },
    {
      path: '/forgot-password',
      name: 'auth.forgotPassword',
      component: require('../../components/Auth/Views/ForgotPassword').default,
    },
    {
      path: '/reset-password',
      name: 'auth.resetPassword',
      component: require('../../components/Auth/Views/ResetPassword').default,
    },
    {
      path: '/email-confirmation',
      name: 'auth.activate',
      component: require('../../components/Auth/Views/Activate').default,
    },
  ]
};

export default auth;
