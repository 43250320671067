/**
 * AntDesign Form is used for validation.
 * Component is connected to Form for using required functions.
 * getFieldDecorator -> Creates a form field with given rules.
 * validateFields -> Checks all fields according to rules and returns errors.
 * "email, code, onetimetoken" parameters are required from query.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Form, Input, Button } from 'antd';
import helpers from '../../../helpers';
import i18n from '../../../plugins/i18n';
import { actions } from '../../../store/modules';
import { Link } from 'react-router-dom';


class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      updateSuccess: null,
      redirectStatus: null,
      user: {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
      },
      onetimetoken: null,
      counter: 9
    };
  };

  componentDidMount() {
    let query = queryString.parse(this.props.location.search);
    if (query.code && query.email && query.onetimetoken) {
      this.setState((state) => ({
        user: {
          ...state.user,
          email: query.email,
          code: query.code
        },
        onetimetoken: query.onetimetoken
      }));
    }
    else {
      this.props.history.push('/sign-in');
    }
  };

  /**
   * Checks if two passwords are consistent.
   */
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  /**
   * Checks if two passwords are consistent.
   */
  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  updateCounter = () => {
    let _this = this;
    this.setState((state) => ({ counter: state.counter - 1 }), () => {
      if (this.state.counter < 1) {
        // auto sign in
        this.login();
      }
      else {
        setTimeout(() => { _this.updateCounter() }, 1000);
      }
    });
  };

  updatePassword = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    let _this = this;
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({ loading: true });
        this.props.dispatch(actions.auth.updatePassword(this.state.user)).then(() => {
          this.setState({ loading: false, updateSuccess: true, errorMessage: '' });
          this.updateCounter();
        }).catch(error => {
          this.setState({ loading: false, updateSuccess: false, errorMessage: helpers.getErrorText(error.errors) }, () => {
            setTimeout(() => {
              _this.setState({ updateSuccess: null });
            }, 2000);
          });
        });
      }
    });
  };

  login = () => {
    let _this = this;
    if (this.state.updateSuccess === true && this.state.redirectStatus === null) {
      let { user } = this.state;
      let data = {
        grant_type: 'password',
        username: user.email,
        password: user.password
      };
      this.props.dispatch(actions.auth.signIn(data)).then(() => {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL;
      }).catch(error => {
        let text = error.error_description ? error.error_description : (error.errors && error.errors[0]) ? error.errors[0].error : '';
        this.setState({ redirectStatus: text });
        setTimeout(() => {
          _this.props.history.push('/sign-in');
        }, 2000);
      });
    }
    else {
      this.props.history.push('/sign-in');
    }
  };

  hasErrors = () => {
    const fields = this.props.form.getFieldsError();
    return !this.state.user.password || !this.state.user.confirmPassword || Object.keys(fields).some(field => fields[field]);
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    let { user, updateSuccess, errorMessage, loading, counter, redirectStatus } = this.state;
    return (
      <div className="page-content">
        <h1 className="text-center">
          <span>{i18n.t(`lbl.updatePassword${redirectStatus !== null ? 'Error' : updateSuccess === true ? 'Success' : ''}`)}</span>
        </h1>
        {redirectStatus !== null &&
          <p>{redirectStatus}</p>
        }
        {updateSuccess !== true &&
          <React.Fragment>
            <Form onSubmit={this.updatePassword} autoComplete="off" className="ForgotForm">
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: i18n.t('msg.required') },
                    { validator: this.validateToNextPassword }
                  ]
                })(
                  <Input type="password" autoComplete="false" placeholder={i18n.t('lbl.newPassword')} onChange={(e) => this.setState({ user: { ...user, password: e.target.value } })} />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('confirm', {
                  rules: [
                    { required: true, message: i18n.t('msg.required') },
                    { validator: this.compareToFirstPassword, }
                  ]
                })(
                  <Input type="password" autoComplete="false" placeholder={i18n.t('lbl.confirmNewPassword')} onChange={(e) => this.setState({ user: { ...user, confirmPassword: e.target.value } })} />
                )}
              </Form.Item>
              {errorMessage !== '' &&
                <span className="error">{errorMessage}</span>
              }
              <Form.Item>
                <Button className={`full-width ${updateSuccess === true ? 'success' : ''}`} type={updateSuccess === false ? 'danger' : 'primary'} htmlType="submit" loading={loading} disabled={this.hasErrors()}>
                  {loading ? i18n.t('btn.resetting') : i18n.t(`btn.${updateSuccess === true ? 'success' : updateSuccess === false ? 'failed' : 'reset'}`)}
                </Button>
              </Form.Item>
            </Form>
          </React.Fragment>
        }
        {(updateSuccess === true && redirectStatus === null) &&
          <p className="text-center">{i18n.t('lbl.redirectingDashboard')} {counter}...</p>
        }
        {redirectStatus !== null &&
          <p>{i18n.t('lbl.redirectToSignIn')}</p>
        }
        {updateSuccess ?
          <div className="BottomLink">
            <span style={{ fontWeight: 'bold', color: '#2b5adc', cursor: 'pointer' }} onClick={this.login}>Click here</span> If you don't want to wait.
          </div>
          :
          <div className="BottomLink">
            <Link to="/sign-in">Go back</Link> to sign in.
          </div>
        }
      </div>
    );
  };
};

const mapStateToProps = ({ auth }) => ({ ...auth });
const ResetPasswordForm = Form.create()(connect(mapStateToProps)(ResetPassword));
export default ResetPasswordForm;