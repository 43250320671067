/**
 * Auth module manages authorization for the application.
 */

import actions from './actions';
import reducers from './reducers';

export default {
  actions: actions,
  reducers: reducers
};
