/**
 * Store is prepared modularly. This project has only one module called Auth.
 */

import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { reducers } from './modules';

const rootReducers = combineReducers(reducers);
let store;
if(process.env.REACT_APP_ENV === 'prod') {
  store = createStore(
    rootReducers,
    applyMiddleware(thunk)
  );
}
else {
  store = createStore(
    rootReducers,
    applyMiddleware(thunk, logger)
  );
}

export default store;
