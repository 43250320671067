/**
 * Routes are defined in a separate file in order to manage easier.
 */

import auth from './auth';

const routes = [
  auth,
];

export default routes;
