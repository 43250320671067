/**
 * Sub Proxy class for managing Authorization requests.
 */

import Proxy from './Proxy';

export class AuthProxy extends Proxy {
  constructor() {
    super(`${process.env.REACT_APP_OAUTH_API_URL}`);
  }

  activate(payload) {
    return this.submit('post', '/account/confirmemail', payload);
  };

  signIn(payload) {
    return this.submit('post', '/token', payload);
  };

  signUp(payload) {
    return this.submit('post', '/account', payload);
  };

  resendEmail(payload) {
    return this.submit('post', '/account/sendemailconfirmationmessage', payload);
  };

  resetPassword(payload) {
    return this.submit('post', '/account/sendresetpasswordmessage', payload);
  };

  updatePassword(payload) {
    return this.submit('post', '/account/resetpassword', payload);
  };
}
