/**
 * AntDesign Form is used for validation.
 * Component is connected to Form for using required functions.
 * getFieldDecorator -> Creates a form field with given rules.
 * validateFields -> Checks all fields according to rules and returns errors.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { actions } from '../../../store/modules';
import i18n from '../../../plugins/i18n';
import { Link } from 'react-router-dom';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      resendLoading: false,
      registerSuccess: null,
      resendSuccess: null,
      errorMessage: null,
      user: {
        email: '',
        name: '',
        lastname: '',
        password: '',
        confirmPassword: '',
        phoneNumber: ''
      },
      errors: []
    };
  };

  /**
   * Checks if two passwords are consistent.
   */
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  /**
   * Checks if two passwords are consistent.
   */
  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  signUp = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    let _this = this;
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({ loading: true });
        this.props.dispatch(actions.auth.signUp(this.state.user)).then(() => {
          this.setState({ loading: false, registerSuccess: true });
        }).catch(error => {
          this.setState({ loading: false, registerSuccess: false, errors: error.errors ? error.errors : [], errorMessage: error.description ? error.description : null }, () => {
            setTimeout(() => {
              _this.setState({ registerSuccess: null });
            }, 2000);
          });
        });
      }
    });
  };

  resendEmail = () => {
    this.setState({ resendLoading: true });
    this.props.dispatch(actions.auth.resendEmail({ email: this.state.user.email })).then(() => {
      this.setState({ resendLoading: false, resendSuccess: true });
    }).catch(() => {
      this.setState({ resendLoading: false, resendSuccess: false });
    });
  };

  hasErrors = () => {
    const { email, name, lastname, password, confirmPassword } = this.state.user;
    const fields = this.props.form.getFieldsError();
    const empty = !email || !name || !lastname || !password || !confirmPassword;
    return empty || Object.keys(fields).some(field => fields[field]);
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    let { user, registerSuccess, errorMessage, loading, resendLoading, resendSuccess, errors } = this.state;
    return (
      <div className="page-content">
        <h1 className="text-center">
          <span>{i18n.t(`lbl.signUp${registerSuccess === true ? 'Success' : ''}`)}</span>
        </h1>
        {registerSuccess === true && resendSuccess === null && resendLoading !== true &&
          <p className="page-text">{i18n.t('lbl.signUpTextSuccess')}</p>
        }
        {resendLoading ?
          <p className="page-text">{i18n.t('lbl.emailResending')}</p>
          :
          resendSuccess !== null &&
          <React.Fragment>
            {resendSuccess ?
              <p className="page-text">{i18n.t('lbl.signUpTextSuccess')}</p>
              :
              <p className="page-text">{i18n.t('lbl.resendError')}</p>
            }
          </React.Fragment>
        }
        {registerSuccess !== true &&
          <React.Fragment>
            <Form onSubmit={this.signUp}>
              <Form.Item hasFeedback>
                {getFieldDecorator('firstName', {
                  rules: errors.find(x => x.field === 'name') ? [] : [
                    { required: true, message: 'Please enter name' }
                  ]
                })(
                  <Input className={`${errors.find(x => x.field === 'name') ? 'has-error' : ''}`} placeholder={i18n.t('lbl.firstName')} onChange={(e) => this.setState({ user: { ...user, name: e.target.value } })} />
                )}
                {errors.find(x => x.field === 'name') && <span className="input-error">{errors.find(x => x.field === 'name').error}</span>}
              </Form.Item>
              <Form.Item hasFeedback>
                {getFieldDecorator('lastName', {
                  rules: errors.find(x => x.field === 'lastname') ? [] : [
                    { required: true, message: 'Please enter surname' }
                  ]
                })(
                  <Input className={`${errors.find(x => x.field === 'lastname') ? 'has-error' : ''}`} placeholder={i18n.t('lbl.lastName')} onChange={(e) => this.setState({ user: { ...user, lastname: e.target.value } })} />
                )}
                {errors.find(x => x.field === 'lastname') && <span className="input-error">{errors.find(x => x.field === 'lastname').error}</span>}
              </Form.Item>
              <Form.Item hasFeedback>
                {getFieldDecorator('email', {
                  rules: errors.find(x => x.field === 'email') ? [] : [
                    { type: 'email', message: 'Please enter a valid email' },
                    { required: true, message: 'Please enter email' }
                  ]
                })(
                  <Input className={`${errors.find(x => x.field === 'email') ? 'has-error' : ''}`} placeholder={i18n.t('lbl.email')} autoComplete="off" onChange={(e) => this.setState({ user: { ...user, email: e.target.value } })} />
                )}
                {errors.find(x => x.field === 'email') && <span className="input-error">{errors.find(x => x.field === 'email').error}</span>}
              </Form.Item>
              <Form.Item hasFeedback>
                {getFieldDecorator('password', {
                  rules: errors.find(x => x.field === 'password') ? [] : [
                    { required: true, message: 'Please enter password' },
                    { validator: this.validateToNextPassword }
                  ]
                })(
                  <Input className={`${errors.find(x => x.field === 'password') ? 'has-error' : ''}`} type="password" autoComplete="off" placeholder={i18n.t('lbl.password')} onChange={(e) => this.setState({ user: { ...user, password: e.target.value } })} />
                )}
                {errors.find(x => x.field === 'password') && <span className="input-error">{errors.find(x => x.field === 'password').error}</span>}
              </Form.Item>
              <Form.Item hasFeedback>
                {getFieldDecorator('confirm', {
                  rules: [
                    { required: true, message: 'Please enter password again' },
                    { validator: this.compareToFirstPassword, }
                  ]
                })(
                  <Input type="password" autoComplete="off" placeholder={i18n.t('lbl.confirmPassword')} onChange={(e) => this.setState({ user: { ...user, confirmPassword: e.target.value } })} />
                )}
              </Form.Item>
              {errorMessage !== null &&
                <span className="error">{errorMessage}</span>
              }
              <Form.Item>
                <Button className={`full-width ${registerSuccess === true ? 'success' : ''}`} type={registerSuccess === false ? 'danger' : 'primary'} htmlType="submit" loading={loading} disabled={this.hasErrors()}>
                  {loading ? i18n.t('btn.signingUp') : i18n.t(`btn.${registerSuccess === true ? 'success' : registerSuccess === false ? 'failed' : 'signUp'}`)}
                </Button>
              </Form.Item>
            </Form>
          </React.Fragment>
        }
        <p className="page-text">
          {registerSuccess === true ?
            <React.Fragment>
              <div>
                {i18n.t('lbl.mailAgain')} <Button className="bold" size="small" onClick={this.resendEmail}>{i18n.t('btn.clickingHere')}</Button>.
              </div>
              <div style={{ marginTop: 10 }}>
                <Link to="/sign-in">Go back to Sign In</Link>
              </div>
            </React.Fragment>
            :
            <div className="BottomLink">
              Do you have an account? <Link to="/sign-in">Sign In</Link>.
            </div>
          }
        </p>
      </div>
    );
  };
};

const mapStateToProps = ({ auth }) => ({ ...auth });
const SignUpForm = Form.create()(connect(mapStateToProps)(SignUp));
export default SignUpForm;