/**
 * AntDesign Form is used for validation.
 * Component is connected to Form for using required functions.
 * getFieldDecorator -> Creates a form field with given rules.
 * validateFields -> Checks all fields according to rules and returns errors.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { actions } from '../../../store/modules';
import i18n from '../../../plugins/i18n';
import { Link } from 'react-router-dom';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      resetSuccess: null,
      user: {
        email: '',
      },
      errorMessage: '',
      counter: 6,
      errors: []
    };
  };

  updateCounter = () => {
    let _this = this;
    this.setState((state) => ({ counter: state.counter - 1 }), () => {
      if (this.state.counter < 1) {
        this.props.history.push('/sign-in');
      }
      else {
        setTimeout(() => { _this.updateCounter() }, 1000);
      }
    });
  }

  resetPassword = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    let _this = this;
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({ loading: true });
        this.props.dispatch(actions.auth.resetPassword(this.state.user)).then(() => {
          this.setState({ loading: false, resetSuccess: true, errorMessage: '' });
          this.updateCounter();
        }).catch(error => {
          this.setState({ loading: false, resetSuccess: false, errors: error.errors ? error.errors : [], errorMessage: i18n.t('msg.resetError') }, () => {
            setTimeout(() => {
              _this.setState({ resetSuccess: null });
            }, 2000);
          });
        });
      }
    });
  };

  hasErrors = () => {
    const fields = this.props.form.getFieldsError();
    return !this.state.user.email || Object.keys(fields).some(field => fields[field]);
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    let { user, resetSuccess, errorMessage, loading, counter, errors } = this.state;
    return (
      <div className="page-content">
        {errorMessage === '' &&
          <h1 className="text-center with-desc small">
            <span>
              {i18n.t(`lbl.resetPassword${resetSuccess === true ? 'Success' : ''}`)}
            </span>
          </h1>
        }
        {errorMessage !== '' &&
          <h1 className="text-center small">
            <span>
              {i18n.t('lbl.errorOccured')}
            </span>
          </h1>
        }
        {errorMessage === '' &&
          <p className="page-text">{i18n.t(`lbl.resetPasswordText${resetSuccess === true ? 'Success' : ''}`)}</p>
        }
        {errorMessage !== '' &&
          <p className="page-text">{errorMessage}</p>
        }
        {resetSuccess !== true &&
          <Form onSubmit={this.resetPassword} className="ForgotForm">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: errors.find(x => x.field === 'email') ? [] : [
                  { type: 'email', message: i18n.t('msg.emailInvalid') },
                  { required: true, message: i18n.t('msg.required') }
                ]
              })(
                <Input className={`${errors.find(x => x.field === 'email') ? 'has-error' : ''}`} placeholder={i18n.t('lbl.email')} onChange={(e) => this.setState({ user: { ...user, email: e.target.value } })} />
              )}
              {errors.find(x => x.field === 'email') && <span className="input-error">{errors.find(x => x.field === 'email').error}</span>}
            </Form.Item>
            <Form.Item>
              <Button className={`full-width ${resetSuccess === true ? 'success' : ''}`} type={resetSuccess === false ? 'danger' : 'primary'} htmlType="submit" loading={loading} disabled={this.hasErrors()}>
                {loading ? i18n.t('btn.resetting') : i18n.t(`btn.${resetSuccess === true ? 'success' : resetSuccess === false ? 'failed' : 'reset'}`)}
              </Button>
            </Form.Item>
          </Form>
        }
        {resetSuccess === true &&
          <p>{i18n.t('lbl.redirecting')} {counter}...</p>
        }
        <div className="BottomLink">
          <Link to="/sign-in">Go back</Link> to sign in.
        </div>
      </div>
    );
  };
};

const mapStateToProps = ({ auth }) => ({ ...auth });
const ForgotPasswordForm = Form.create()(connect(mapStateToProps)(ForgotPassword));
export default ForgotPasswordForm;