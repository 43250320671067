/**
 * Auth page's layout. 
 */

import React, { Component } from 'react';
import { Layout } from 'antd';
import AuthContent from './Content';
import Logo from '../../../assets/img/logo-light.svg';
import Logo2 from '../../../assets/img/logo.svg';

class AuthLayout extends Component {

  render() {
    return (
      <>
        <header>
          <img src={Logo2} alt="" />
        </header>
        <Layout className="auth-layout">
          <div className="Info">
            <div>
              <img src={Logo} alt="" />
            </div>
            <div className="mt-auto">
              <h1>Lorem Ipsum dolor sit amet</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div className="mt-auto bottom">
              <a href="https://chatbot-landing.vercel.app/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> and <a href="https://chatbot-landing.vercel.app/terms-service" rel="noopener noreferrer" target="_blank">Terms of Conditions</a>
            </div>
          </div>
          <AuthContent />
        </Layout>
      </>
    );
  };
};

export default AuthLayout;