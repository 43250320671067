import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import locales from './locales';

let lang = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en';

i18n
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, 
    },
    lng: lang,
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    resources: locales
  });


export default i18n;