import * as types from './action-types';
import state from './state';

const initialState = state;

export default (state = initialState, action) => {
  let obj;
  switch (action.type) {
    case types.SET_ACCOUNT_DATA:
      obj = state[action.payload.key];
      obj = action.payload.data;
      return {
        ...state,
        [action.payload.key]: [...obj]
      };
    case types.SET_SINGLE_ACCOUNT_DATA:
      if (action.payload.key && action.payload.data) {
        obj = state[action.payload.key];
        obj = action.payload.data;
        return {
          ...state,
          [action.payload.key]: { ...obj }
        };
      };
      break;
    case types.ADD_ACCOUNT_DATA:
      obj = state[action.payload.key];
      obj.push(action.payload.data);
      return {
        ...state,
        [action.payload.key]: [...obj]
      };
    case types.UPDATE_ACCOUNT_DATA:
      if (action.payload.key && action.payload.index !== null && action.payload.index !== undefined) {
        obj = state[action.payload.key];
        obj[action.payload.index] = action.payload.data.model;
        return {
          ...state,
          [action.payload.key]: [...obj]
        }
      }
      break;
    case types.DELETE_ACCOUNT_DATA:
      obj = state[action.payload.key];
      obj.splice(action.payload.index, 1);
      return {
        ...state,
        [action.payload.key]: [...obj]
      };
    default:
      return state;
  }
}
