/**
 * Tokens are being stored in cookies in order to reach from all subdomains.
 */

import { AuthProxy } from '../../../proxies';
import cookie from 'js-cookie';

export default {
  signIn: (payload) => {
    return dispatch => new Promise((resolve, reject) => {
      return new AuthProxy().signIn(payload).then(response => {
        cookie.set('access_token', response.access_token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        cookie.set('refresh_token', response.refresh_token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
        resolve();
      }).catch(error => {
        reject(error);
      })
    });
  },
  signUp: (payload) => {
    return dispatch => new Promise((resolve, reject) => {
      return new AuthProxy().signUp(payload).then(response => {
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  resendEmail: (payload) => {
    return dispatch => new Promise((resolve, reject) => {
      return new AuthProxy().resendEmail(payload).then(response => {
        resolve();
      }).catch(error => {
        reject();
      });
    });
  },
  resetPassword: (payload) => {
    return dispatch => new Promise((resolve, reject) => {
      return new AuthProxy().resetPassword(payload).then(response => {
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  updatePassword: (payload) => {
    return dispatch => new Promise((resolve, reject) => {
      return new AuthProxy().updatePassword(payload).then(response => {
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  activate: (payload) => {
    return dispatch => new Promise((resolve, reject) => {
      return new AuthProxy().activate(payload).then(response => {
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  check: () => {
    return dispatch => new Promise((resolve, reject) => {
      let accessToken = cookie.get('access_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      if (accessToken) {
        window.location.href = process.env.REACT_APP_DASHBOARD_URL;
      }
      else {
        reject();
      }
    });
  },
  logout: () => {
    return dispatch => new Promise(() => {
      localStorage.removeItem('currentBot');
      cookie.remove('access_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      cookie.remove('refresh_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      cookie.remove('facebook_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
      window.location.replace(process.env.REACT_APP_OAUTH_URL);
    });
  }
};
