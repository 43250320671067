/**
 * Service class for managing API requests via Axios. 
 * Query parameters are passed to constructor while body is passed to the function.
 * Basic usage after importing : new Proxy(parameters).submit('example-url', body)
 */

import axios from 'axios';
import formurlencoded from 'form-urlencoded';
import cookie from 'js-cookie';
let token = cookie.get('access_token', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
if (token) axios.defaults.headers.Authorization = `bearer ${token}`;

export class Proxy {
  constructor(endpoint = '', parameters = {}) {
    this.endpoint = endpoint;
    this.parameters = parameters;
  }

  /**
   * Converts given query object into a valid query string without any modification in actions.
   */
  getParameterString(obj, prefix) {
    var str = [], p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.getParameterString(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  };

  /**
   * 
   * @param {String} requestType HTTP method to be used.
   * @param {String} url Where to send the request.
   * @param {Object} data Body of the request (leave empty if there is no data).
   */
  submit(requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      let headers = {};
      let _data = data;
      if (url === '/token') {
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        _data = formurlencoded(data);
      }

      let str = this.getParameterString(this.parameters);
      axios[requestType](this.endpoint + url + (str !== '' ? `?${str}` : ''), _data, { headers }).then(response => {
        resolve(response.data);
      }).catch(error => {
        if (error.response) {
          reject(error.response.data);
        }
        else if (error.message) {
          reject({ description: error.message });
        }
        else {
          reject();
        }
      });
    });
  };
}

export default Proxy;
