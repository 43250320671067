import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import routes from './plugins/routes';

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          {routes.map((route, i) => {
            return <Route
              path={route.path}
              component={route.component}
              key={`r-${i}`}
            />;
          })}
        </Switch>
      </BrowserRouter>
    );
  };
};

export default App;
