/**
 * Helpers are global functions/variables which can be used in any part of the project.
 */

import React from 'react';

export default {
  /**
   * Prints given errors in separate lines.
   */
  getErrorText: (data) => {
    return (
      <React.Fragment>
        { data && data.length > 0 &&
          data.map((item, i) => <p className="error-text" key={i}>{`* ${item.error.toLowerCase()}`}</p>)
        }
      </React.Fragment>
    );
  },
};