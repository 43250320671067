/**
 * Each action and reducer from modules are combined together.
 */

import auth from './auth';

export const actions = {
  auth: { ...auth.actions },
};

export const reducers = {
  auth: auth.reducers,
};