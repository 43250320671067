import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import auth from '../../../plugins/routes/auth';
import { Layout } from 'antd';

class AuthContent extends Component {
  render() {
    return (
      <Layout.Content className="auth-content">
        <div>
          <Switch>
            {auth.routes.map((route, i) => {
              return <Route path={`${route.path}`} component={route.component} key={`ra-${i}`} />;
            })}
            <Redirect from="/" to="/sign-in" />
          </Switch>
        </div>
      </Layout.Content>
    );
  };
};

export default AuthContent;