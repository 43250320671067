/**
 * AntDesign Form is used for validation.
 * Component is connected to Form for using required functions.
 * getFieldDecorator -> Creates a form field with given rules.
 * validateFields -> Checks all fields according to rules and returns errors.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { actions } from '../../../store/modules';
import i18n from '../../../plugins/i18n';
import { Link } from 'react-router-dom';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loginSuccess: null,
      errorMessage: '',
      user: {
        username: '',
        password: '',
        grant_type: 'password'
      }
    };
  };

  componentDidMount() {
    /**
     * If user has already logged in, sends directly to the dashboard.
     */
    this.props.dispatch(actions.auth.check()).catch(() => {
      this.setState({ loading: false });
    });
  };

  signIn = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    let _this = this;
    this.props.form.validateFields(error => {
      if (!error) {
        this.setState({ loading: true });
        this.props.dispatch(actions.auth.signIn(this.state.user)).then(() => {
          this.setState({ loading: false, loginSuccess: true });
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_DASHBOARD_URL;
          }, 1000);
        }).catch(error => {
          this.setState({ loading: false, loginSuccess: false, errorMessage: (error && error.error_description) ? error.error_description : i18n.t('msg.loginError') }, () => {
            setTimeout(() => {
              _this.setState({ loginSuccess: null });
            }, 2000);
          });
        });
      }
    });
  };

  hasErrors = () => {
    const fields = this.props.form.getFieldsError();
    return !this.state.user.password || !this.state.user.username || Object.keys(fields).some(field => fields[field]);
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    let { user, loginSuccess, errorMessage, loading } = this.state;
    return (
      <div className="page-content">
        <h1 className="text-center">
          <span>
            {i18n.t('lbl.signIn')}
          </span>
        </h1>
        <Form onSubmit={this.signIn}>
          <Form.Item hasFeedback>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please enter email' },
                { required: true, message: 'Please enter a valid email' }
              ]
            })(
              <Input placeholder={i18n.t('lbl.email')} onChange={(e) => this.setState({ user: { ...user, username: e.target.value } })} />
            )}
          </Form.Item>
          <div className="PasswordInput">
            <Form.Item hasFeedback>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please enter password' }]
              })(
                <Input type="password" placeholder={i18n.t('lbl.password')} onChange={(e) => this.setState({ user: { ...user, password: e.target.value } })} />
              )}
            </Form.Item>
            <Link to="/forgot-password">{i18n.t('btn.forgotPassword')}</Link>
          </div>
          {errorMessage !== '' &&
            <span className="error">{errorMessage}</span>
          }
          <Form.Item>
            <Button className={`full-width ${loginSuccess === true ? 'success' : ''}`} type={loginSuccess === false ? 'danger' : 'primary'} htmlType="submit" loading={loading} disabled={this.hasErrors()}>
              {loading ? i18n.t('btn.signingIn') : i18n.t(`btn.${loginSuccess === true ? 'success' : loginSuccess === false ? 'failed' : 'signIn'}`)}
            </Button>
          </Form.Item>
        </Form>
        <div className="BottomLink">
          Don't have an account? <Link to="/sign-up">Sign Up</Link>.
        </div>
      </div>
    );
  };
};

const mapStateToProps = ({ auth }) => ({ ...auth });
const SignInForm = Form.create()(connect(mapStateToProps)(SignIn));
export default SignInForm;