export default {
  translation: {
    btn: {
      clickHere: "Click here",
      clickingHere: "clicking here",
      failed: "Failed",
      forgotPassword: "Forgot password",
      goBack: "Go back",
      join: "Register",
      reset: "Reset",
      resetting: "Resetting",
      signIn: "Sign in",
      signUp: "Sign up",
      signingIn: "Signing in",
      signingUp: "Signing up",
      signInFacebook: "Sign in with Facebook",
      signUpFacebook: "Sign up with Facebook",
      success: "Success",
    },
    lbl: {
      activate: "Activate",
      activateSuccess: "Account successfully activated!",
      activateError: "An error occured.",
      activateTextSuccess: "Thank you for registering and activating your account.",
      dontWait: "if you don't want to wait.",
      email: "E-mail",
      errorOccured: "An error has occured while processing your request.",
      free: "for free.",
      firstName: "First Name",
      lastName: "Last Name",
      needAccount: "Need an account?",
      confirmPassword: "Confirm password",
      confirmNewPassword: "Confirm new password",
      haveAccount: "Already have an account?",
      mailAgain: "If you don’t have the e-mail you can get a new confirmation link",
      redirecting: "Redirecting to sign in page in",
      redirectToSignIn: "Redirecting to sign in page...",
      redirectingDashboard: "Redirecting to Dashboard in",
      resetPassword: "Reset your password",
      resetPasswordSuccess: "Your password reset link has been sent.",
      resetPasswordText: "Enter your email address below and we'll send you a link to reset your password.",
      resetPasswordTextSuccess: "You can reset your password by clicking the link  sent to your e-mail address.",
      updatePassword: "Reset your password",
      updatePasswordSuccess: "Your password successfully changed.",
      updatePasswordError: "An error occured.",
      updatePasswordText: "Please enter your new password. Make sure it is different than your last one.",
      updatePasswordTextSuccess: "You will be redirected to the dashboard.",
      password: "Password",
      newPassword: "New password",
      signIn: "Sign in",
      signUp: "Sign up",
      signUpSuccess: "Account successfully created!",
      signUpTextSuccess: "Please verify your account with the confirmation link provided to your e-mail address.",
      toSignIn: "to Sign in page.",
      emailResending: "Resending email...",
      resendError: "An error occured while resending email."
    },
    msg: {
      emailInvalid: "* please enter a valid e-mail",
      required: "* required",
      loginError: "* invalid username or password",
      resetError: "User could not be found. Please make sure you entered your e-mail address correctly.",
      autoLoginError: "An error occured. Redirecting to sign in page..."
    }
  }
}